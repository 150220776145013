@media screen and (max-width: 1024px) {
  .contactlefthead {
    color: #fff;
    font-size: 52px;
    font-family: "OpenSans-ExtraBold";
    text-align: left;
  }

  .contbanpara {
    color: #fff;
    font-size: 23px;
    /* margin-top: 10px !important; */
  }

  .downleftpic {
    width: 42px;
    height: auto;
  }

  .downlpara p {
    color: #fff;
    font-size: 12px;
    margin-bottom: 0px;
    text-align: left;
  }

  .scheludemeeting {
    text-wrap: nowrap;
  }

  .expertsrole {
    font-size: 10px;
    text-wrap: nowrap;
  }

  .expertsname {
    font-size: 16px;
    text-wrap: nowrap;
  }

  .contact-banner {
    height: auto;
    width: 100%;
    display: flex;
    padding: 50px 0px;
  }

  .inputscroll {
    height: auto;
    overflow: auto;
  }

  .banner-sub-heading {
    font-size: 23px;
    padding: 0px 72px;
  }

  .about-banner {
    height: auto;
    width: 100%;
    display: flex;
    padding: 50px 0px;
  }

  .expert-banner {
    height: 50vh;
    width: 100%;
  }

  .about-banner {
    height: 50vh;
  }

  .abt-break-tag {
    display: none;
  }

  .aboutpara {
    text-wrap: wrap;
  }

  .abtpoints {
    text-wrap: nowrap;
  }

  .home-banner {
    height: 50vh;
  }

  .banner-heading {
    font-size: 50px;
  }

  .expertimgban {
    width: 65%;
    right: 30%;
    bottom: -10%;
    transform: translate(20%, 0%);
  }

  .result-banner {
    height: 50vh;
    position: relative;
  }

  br.break-tag {
    display: none;
  }

  .meetingpara2 {
    text-wrap: wrap;
  }

  .btn-book-expert-call {
    padding: 12px 22px;
  }
}

@media screen and (max-width: 992px) {
  /* .navbar {
    background-color: #00000069 !important;
  } */

  .home-video-para {
    font-size: 25px;
    line-height: 40px;
    margin-top: 20px;
  }

  .inputscroll {
    height: auto;
    overflow-y: scroll;
  }

  .contact-banner {
    height: auto;
    width: 100%;
    padding: 50px 0px;
  }

  .contacttopdiv {
    display: flex;
    justify-content: center;
    padding: 30px;
  }

  .scheludemeeting {
    text-wrap: wrap;
    margin-left: 0px !important;
    padding-left: 13%;
    margin-top: 30px;
  }

  .meetingsflex {
    margin-top: 0px;
  }

  .conlasthead {
    font-size: 27px;
    padding: 0px 12%;
  }

  .expertimgban {
    width: 63%;
    right: 28%;
    bottom: -15%;
    transform: translate(16%, 0%);
  }

  .expertfirsthead {
    margin-top: 23%;
  }

  .Expertheading1 {
    font-size: 55px;
  }

  .expertsrole {
    font-size: 16px;
    height: 70px;
    text-wrap: wrap;
  }

  .expertsname {
    font-size: 26px;
    text-wrap: wrap;
  }

  .expertsicon {
    width: 30px;
    height: 30px;
  }

  .banner-heading {
    font-size: 52px;
    margin-bottom: 10px !important;
  }

  .aboutsection {
    margin-top: 35px;
  }

  .break-tag {
    display: none;
  }

  .aboutpara {
    text-wrap: wrap;
  }

  .abtcfounderpara {
    margin-top: 30px;
  }

  .foundbtopara {
    margin-top: 25px;
  }

  .secmargintop {
    margin-top: 30px;
  }

  .hands-on-content-mob {
    display: flex;
    flex-wrap: wrap;
  }

  .section-heading {
    font-size: 38px;
  }

  .navbar {
    background-color: black !important;
  }

  .result-tab {
    font-size: 15px;
  }

  .result-image {
    width: 70px;
  }
}

@media screen and (max-width: 760px) {
  .contactlefthead {
    color: #fff;
    font-size: 34px;
    font-family: "OpenSans-ExtraBold";
    text-align: left;
  }

  .result-banner {
    height: 100vh;
  }

  .result-image {
    width: 75px;
  }

  .contbanpara {
    color: #fff;
    font-size: 13px;
    margin-top: 10px !important;
  }

  .downleftpic {
    width: 32px;
    height: auto;
  }

  .downlpara p {
    color: #fff;
    font-size: 8px;
    margin-bottom: 0px;
    text-align: left;
  }

  .header-logo {
    width: 150px;
    height: auto;
  }

  .inputscroll {
    height: auto;
    overflow-y: hidden;
  }

  .conlasthead {
    font-size: 21px;
    padding: 0px 6%;
  }

  .scheludemeeting {
    text-wrap: wrap;
    margin-left: 0px !important;
    padding-left: 20px;
    margin-top: 30px;
  }

  .conlastdiv {
    background: linear-gradient(107.71deg, #121112 61.3%, #d32b3a 125.34%);
    margin: 70px 0px;
    border-radius: 15px;
    padding: 40px 0px !important;
  }

  /* experts */
  .banner-heading {
    font-size: 34px;
    padding: 0px 10px;
    margin-bottom: 10px !important;
  }

  .banner-sub-heading {
    font-size: 13px;
  }

  .expertimgban {
    width: 80%;
    height: auto;
    position: absolute;
    right: 23%;
    bottom: -7%;
    transform: translate(16%, 0%);
  }

  .Expertheading1 {
    font-size: 35px;
  }

  .section-heading {
    font-size: 30px;
    margin-bottom: 15px !important;
  }

  .expert-banner {
    height: 60vh;
  }

  .about-banner {
    height: 60vh;
  }

  .aboutsection {
    margin-top: 25px;
  }

  .aboutheading1 {
    font-size: 34px;
    margin-bottom: 10px;
  }

  .aboutpara {
    text-wrap: wrap;
  }

  .abtpoints {
    font-size: 14px;
    text-wrap: wrap;
  }

  .aboutspcbtwn {
    justify-content: space-between;
    gap: 20px;
  }

  .tickicons {
    width: 8px;
    height: 8px;
    margin-top: 2px;
    padding: 5px;
  }

  .abtcfounderpara {
    margin-top: 20px;
  }

  .foundbtopara {
    margin-top: 20px;
  }

  .secmargintop {
    margin-top: 15px;
  }

  .aboutheading2 {
    font-size: 30px;
    margin-top: 10px;
    padding-left: 12px;
  }

  .aboutheading3 {
    font-size: 30px;
    line-height: 40px;
  }

  .ubuntudiv {
    margin: 30px 0px 40px;
  }

  .results-content .flexRow {
    flex-direction: column;
  }

  .results-content .Modal-View {
    width: 98% !important;
  }

  .results-content .close-btn {
    right: 1px;
    top: -15px;
  }

  .footerRowContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }

  .nav-link {
    margin: 10px 0px;
  }

  .home-student-says-section .owl-carousel .owl-nav button.owl-prev,
  .home-student-says-section .owl-carousel .owl-nav button.owl-next {
    top: auto;
  }

  .eagle-award-home-left-content {
    font-size: 35px;
    line-height: 60px;
  }

  .eagle-award-gradient {
    font-size: 35px;
  }

  .course-card-content {
    margin-bottom: 20px;
  }

  .modal-backdrop {
    z-index: 2040;
  }

  .speak-withus-drop .Modal-View {
    width: 88%;
  }

  .btn-book-expert-call {
    padding: 12px 16px;
    font-size: 14px;
  }

  .btn-explore-course {
    padding: 12px 16px;
    font-size: 14px;
  }

  .home-banner {
    height: 75vh;
  }

  .pointsflex {
    width: 46%;
  }

  .expertsrole {
    height: auto;
  }

  .result-tab {
    font-size: 15px;
  }

  .contact-page-content .modal-content {
    width: 100% !important;
  }

  .contact-page-content .Modal-View {
    width: 90% !important;
  }

  .contact-btn {
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%);
  }

  .results-header {
    position: relative;
    top: auto;
  }

  .btn-check:active + .btn-primary:focus,
  .btn-check:checked + .btn-primary:focus,
  .btn-primary.active:focus,
  .btn-primary:active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%);
  }

  .students-testi-para {
    height: auto;
  }

  .students-testi-heading {
    height: auto;
  }

  .width-left-circle-content {
    width: 100%;
  }

  .hands-on-content-mob {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  .hands-on-left-text {
    text-wrap: nowrap;
    margin-right: 10px;
  }

  .footer-heading-gradient {
    text-align: center;
  }

  .footer-heading {
    text-align: center;
  }

  .rating-img-mob {
    justify-content: center !important;
    margin-top: 30px;
  }

  .footer-logo {
    width: 100%;
    margin: auto;
  }

  .footer-text-copyright {
    color: #d8d4d4;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
  }

  .expertbanner-content {
    margin-bottom: unset;
  }

  .events-tab {
    /* background: linear-gradient(90.5deg, #121112 0.43%, #741E26 52.47%, #D32B3A 103.11%);   */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-family: "OpenSans-Bold";
    text-decoration: none;
    font-size: 15px;
    padding: 5px 0px;
    margin: auto 10px;
  }

  .owl-theme .owl-nav {
    display: none;
  }

  .blog-tab-section {
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    top: auto;
  }

  .blogs-tab-content {
    font-size: 16px;
  }

  .course-videos {
    height: auto;
  }
}
