@font-face {
  font-family: "OpenSans-Regular";
  src: url("./assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("./assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("./assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("./assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

* {
  font-family: "OpenSans-Regular";
}

.overflow-hidden {
  overflow-x: hidden;
}

.contact-banner {
  background-image: url("./assets/images/home-banner.webp");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contactlefthead {
  color: #fff;
  font-size: 58px;
  font-family: "OpenSans-ExtraBold";
  text-align: left;
}

.contbanpara {
  color: #fff;
  font-size: 17px;
}

.downlpara p {
  color: #fff;
  font-size: 14px;
  /* font-family: "OpenSans-Bold"; */
  margin-bottom: 0px;
  text-align: left;
}

.downbtn {
  background: linear-gradient(94.53deg, #121112 -212.93%, #d32b3a 110.53%);
  padding: 10px;
  border: none;
  border-radius: 5px;
  position: relative;
  animation-name: shake;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  cursor: pointer;
}
.downbtn:hover {
  animation-name: shakeAnim;
}
@keyframes shakeAnim {
  0% {
    left: 0
  }
  10% {
    left: -3px
  }
  20% {
    left: 3px
  }
  30% {
    left: -8px
  }
  40% {
    left: 8px
  }
  50% {
    left: -5px
  }
  60% {
    left: 3px
  }
  70% {
    left: 0
  }
}

@keyframes shake {
  0% {
    left: 0
  }
  10% {
    left: -3px
  }
  20% {
    left: 3px
  }
  30% {
    left: -8px
  }
  40% {
    left: 8px
  }
  50% {
    left: -5px
  }
  60% {
    left: 3px
  }
  70% {
    left: 0
  }
}
.paddiv {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
}

.paddiv p {
  color: #fff;
  font-size: 11px;
  margin-bottom: 0px;
  text-align: left;
}

.downicon {
  color: #fff;
  padding-left: 10px;
  font-size: 13px;
}

.downbtndiv {
  display: flex;
  flex-direction: row;
}

.downleftpic {
  width: 50px;
  height: auto;
}

.contact-parent-div {
  background: var(--Dark-Woodsmoke-50, #f8f7f8);
  padding: 30px;
  border-radius: 10px;
}

.contbanleftflex {
  display: flex;
}

.contactinputs label {
  color: "#000000";
  font-family: "OpenSans-Bold";
  font-size: 12px;
  margin-top: 16px;
}

.inputscroll {
  height: 58vh;
  overflow-y: scroll;
}

.contactinputs {
  display: flex;
  flex-direction: column;
}

.contactinputs input {
  margin-top: 8px;
  height: 40px;
  font-size: 14px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 13px;
  border-bottom: 1px solid;
  border-bottom-color: #e90017 !important;
}

.contactinputs input:focus-visible {
  border: none !important;
  box-shadow: none !important;
  outline: 0px;
}

/* :focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
} */
.contactinputs select {
  margin-top: 5px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 0px 0px 0px 13px;
  border-bottom: 1px solid;
  border-bottom-color: #e90017 !important;
}

.option {
  padding: 10px;
}

.submitbtn {
  background: #d32b3a;
  padding: 7px 25px;
  border: 1px solid #d32b3a;
  border-radius: 5px;
  color: white;
  font-family: "OpenSans-Bold";
  margin-top: 15px;
  font-size: 12px;
}

/* .submitbtn:hover {
  background: white;
  padding: 7px 17px;
  border: 1px solid #d32b3a;
  border-radius: 5px;
  color: red;
  font-family: "OpenSans-Bold";
  margin-top: 15px;
  font-size: 12px;
} */
.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  /* padding: 0px 17px; */
  color: white !important;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border2 {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  /* padding: 0px 17px; */
  color: #d32b3a !important;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.contacth2 {
  background: linear-gradient(88.43deg, #121112 0.23%, #d32b3a 44.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  font-family: "OpenSans-ExtraBold";
  padding-bottom: 2px;
}

.contactflex {
  display: flex;
  flex-direction: row;
}

.contacticon {
  margin-top: 6px;
  color: #e90017;
}

.anchor {
  text-decoration: none;
  color: #000000;
  font-family: "OpenSans-SemiBold";
  text-align: center;
}
.anchor:hover {
  color: #e90017;
}
.locationpara {
  color: #000000;
  font-family: "OpenSans-SemiBold";
  text-align: left;
}

.googlemap iframe {
  width: 100%;
  height: 450px;
}

.contacttopdiv {
  display: flex;
  justify-content: center;
  padding-top: 15%;
}

.conlastdiv {
  background: linear-gradient(107.71deg, #121112 61.3%, #d32b3a 125.34%);
  margin: 70px 0px;
  border-radius: 15px;
  padding: 55px 0px;
}

.conlastdivmodel {
  background: linear-gradient(107.71deg, #121112 61.3%, #d32b3a 125.34%);
  margin: 0px;
  border-radius: 15px;
  padding: 55px 0px;
  border: 1px solid #a01d26;
}

.conlasthead {
  font-family: "OpenSans-ExtraBold";
  text-align: center;

  background: linear-gradient(90.5deg, #ffffff 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.meetingicondiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.meetingpara {
  color: white;
  padding-left: 10px;
  font-size: 20px;
  margin-bottom: 0px;
}

.meetingpara2 {
  color: white;
  font-size: 14px;
}

.expercallbtn {
  color: white;
  background-color: #2154d9;
  padding: 10px 25px;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  font-family: "OpenSans-Bold";
  animation: expertcallbookcall 1000ms infinite ease-out;
}
@keyframes expertcallbookcall {
  0% {
    box-shadow: inset 0px 0px 0px 5px #2155d997, 0px 0px 0px 0px #2154d9;
  }

  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(41, 39, 39, 0.2), 0px 0px 0px 10px rgba(255, 255, 255, 0);
  }
}
.meetingsflex {
  margin-top: 40px;
}

.expercallbtn2 {
  color: black;
  background-color: #48c858;
  padding: 10px 25px;
  font-size: 12px !important;
  font-family: "OpenSans-Bold";
  border: none;
  border-radius: 5px;
  text-decoration: none;
  animation: expertcallwhatss 1000ms infinite ease-out;
}
@keyframes expertcallwhatss {
  0% {
    box-shadow: inset 0px 0px 0px 5px #48c8597d, 0px 0px 0px 0px #48c858;
  }

  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(41, 39, 39, 0.2), 0px 0px 0px 10px rgba(255, 255, 255, 0);
  }
}
.expercallbtn2:hover {
  color: black !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.expertimgban {
  width: 60%;
  height: auto;
  position: absolute;
  right: 30%;
  bottom: -7%;
  transform: translate(16%, 0%);
}

.expertsiconsdiv {
  display: flex;
  justify-content: center;
}

.expertcard {
  background: linear-gradient(112.26deg, #121112 51.34%, #d32b3a 147.24%);
  padding: 30px 20px;
  border-radius: 10px;
}

.expertsname {
  text-align: center;
  color: white;
  font-family: "OpenSans-Bold";
  font-size: 20px;
  margin-bottom: 5px;
  text-wrap: nowrap;
}

.expertsrole {
  text-align: center;
  color: white;
  font-size: 13px;
  margin-bottom: 15px;
  text-wrap: nowrap;
}

.expertsicon {
  width: 20px;
  height: 20px;
}

.expertsicon:hover {
  transition: transform 0.3s ease;
  transform: translateY(-20%);
  filter: blur(1px);
}

.exportimgs {
  width: 100%;
  height: auto;
}

.Expertheading1 {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  font-family: "OpenSans-ExtraBold";
  text-align: center;
}

.expertfirsthead {
  margin-top: 10%;
}

.expertsecondhead {
  margin: 120px 0px 30px;
}

.expertcardpad {
  background: linear-gradient(112.26deg, #121112 51.34%, #d32b3a 147.24%);
  padding: 30px 10px !important;
  border-radius: 10px;
}

.expertslastsec {
  margin-top: 100px;
  padding: 70px 0px;
}

.digitaldiv {
  margin: 65px 0px;
}

.digitalhead {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  font-family: "OpenSans-ExtraBold";
  line-height: 55px;
  margin-bottom: 40px;
}

.digital-ques {
  color: #1e1e1e;
  font-family: "OpenSans-Bold";
  font-size: 20px;
}

.digital-ans {
  font-size: 15px;
  margin-bottom: 30px;
}

.ques-ans-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #00000080;
}

.digitalpad {
  padding-right: 10px;
}

.ques-ans-divlast {
  display: flex;
  justify-content: space-between;
}

/* .founrghtleft {
  padding-left: 90px;
} */
.abtnames {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  font-family: "OpenSans-ExtraBold";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 15px;
}

.pedology-left-div {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.abtroles {
  color: #1e1e1e;
  font-family: "OpenSans-SemiBold";
  margin-bottom: 0px;
}

.pedology-percent {
  font-family: "OpenSans-ExtraBold";
  margin-bottom: 0px;
  font-size: 36px;
}

.pedology-left-inner {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-evenly;
}

.pedology-left-inner img {
  width: 150px;
  height: auto;
}

.pedo-inner-para {
  font-family: "OpenSans-ExtraBold";
}

.pedology-left-div {
  background-color: #fbf9eb;
  padding: 70px 30px;
}

.pedology-percent-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-backdrop {
  background-color: rgb(0 0 0 / 71%) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
  /* background: linear-gradient(180deg, #000000 0%, #373636 50.52%, #000000 100%); */
  /* border: 2px solid #ffc107; */
  position: relative;
}

.Modal-View {
  width: 70%;
}

.expert-banner {
  background-image: url("./assets/images/expertbackimg.webp");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.close-btn>i {
  color: #d32b3a;
  /* background: #ffffff; */
  border-radius: 50%;
}

.close-btn>i:hover {
  color: #060606;
  background-color: #ffffff;
}

.close-btn {
  position: absolute;
  right: -10px;
  top: -10px;
}

.fa-circle-xmark {
  font-size: 35px;
  border: 1px solid white;
  background: #ffffff;
}

.errorpara {
  color: red;
  font-size: 10px;
  margin-bottom: 0px;
  margin-top: 2px;
}

.close-icon-broucher {
  text-shadow: rgba(171, 167, 167, 0.44) 0px 3px 6px;
  position: absolute;
  right: 16px;
  top: 6px;
  font-size: 19px;
  color: black;
  padding: 2px;
  cursor: pointer;
}

.close-icon-broucher:hover {
  text-shadow: rgba(171, 167, 167, 0.44) 0px 3px 6px;
  position: absolute;
  right: 16px;
  top: 6px;
  font-size: 19px;
  color: #d32b3a;
  padding: 2px;
  cursor: pointer;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 35px;
  /* margin-bottom: 10px; */
}

.form-control:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border-color: black;
}

.broucher-label {
  font-size: 13px;
  font-family: "OpenSans-Bold";
}

.sendhead {
  font-size: 20px;
  font-family: "OpenSans-Bold";
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  font-family: "OpenSans-ExtraBold";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-primary {
  color: #fff;
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  border-radius: 8px;
  font-family: "OpenSans-Bold";
  border: 1px solid black;
}

.btn-primary:hover {
  /* color: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  background-color: white; */
  color: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "OpenSans-Bold";
  border: 1px solid black;
  border-radius: 8px;
}

.success-img {
  width: 120px;
  height: auto;
}

.thnk-modal-logo {
  width: 180px;
  height: auto;
}

.expertcardpadgana {
  background: linear-gradient(112.26deg, #121112 51.34%, #d32b3a 147.24%);
  padding: 30px 5px !important;
  border-radius: 10px;
}

.inputscroll::-webkit-scrollbar {
  width: 5px;

}

.inputscroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.inputscroll::-webkit-scrollbar-thumb {
  background: #d32b3a;
  border-radius: 10px;
}

.inputscroll::-webkit-scrollbar-thumb:hover {
  background: #d32b3a;
}