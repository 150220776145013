/* opan sans */
@font-face {
  font-family: "OpenSans-Regular";
  src: url("../assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("../assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

* {
  font-family: "OpenSans-Regular";
}

.header-logo {
  width: 200px;
  height: auto;
}

.navbar {
  position: fixed;
  z-index: 2000;
  width: 100%;
  /* background-color: #0000007d;
  backdrop-filter: blur(5px); */
}

/* Buttons */
.btn-speak-with-us {
  background-color: #d32b3a;
  color: #fff;
  padding: 8px 20px;
  font-size: 14px;
  border: 2px solid #d32b3a;
  border-radius: 8px;
  font-family: "OpenSans-SemiBold";
}

.btn-speak-with-us:hover {
  background-color: #ffffff00;
  color: #d32b3a;
  border: 2px solid #d32b3a;
}

.btn-book-expert-call {
  background-color: #ffffff;
  color: #d32b3a;
  padding: 12px 25px;
  font-size: 16px;
  font-family: "OpenSans-SemiBold";
  border: 2px solid #fff;
  border-radius: 8px;
  animation: borderPulse 1000ms infinite ease-out;
}

.btn-book-expert-call:hover {
  background-color: #ffffff00;
  border: 2px solid #fff;
  color: #ffffff;
}

.btn-explore-course {
  background-color: #d32b3a;
  border: 2px solid #d32b3a;
  color: #fff;
  padding: 12px 25px;
  font-size: 16px;
  font-family: "OpenSans-SemiBold";
  border-radius: 8px;
  animation: borderPulseVideo 1000ms infinite ease-out;
}

.btn-explore-course:hover {
  background-color: #00000000;
  border: 2px solid #d32b3a;
  color: #d32b3a;
}

/* Buttons */

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.nav-link {
  font-size: 14px;
  color: #fff !important;
  font-family: "OpenSans-SemiBold";
}

.nav-link:hover,
.nav-link.active {
  font-size: 14px;
  color: #d32b3a !important;
}

.footer {
  background: linear-gradient(105.8deg, #121112 44.46%, #d32b3a 364.13%);
}

.footer-heading {
  color: #fff;
  font-size: 34px;
  font-family: "OpenSans-Bold";
}

.footer-heading-gradient {
  font-size: 34px;
  font-family: "OpenSans-Bold";
  background: linear-gradient(90.5deg, #ffffff 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.footer-logo {
  width: 250px;
  height: auto;
}

.google-rating-img {
  width: 100%;
  height: auto;
  text-align: center;
}

.footer-text-copyright {
  color: #fff;
  font-size: 14px;
  text-align: right;
}

.footer-text-link {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}

.footer-text-link:hover {
  color: #d32b3a;
}

.social-media-links .fa-brands {
  color: #fff;
  font-size: 24px;
}

.social-media-links .fa-facebook:hover {
  color: #0c4cfa;
}

.social-media-links .fa-instagram:hover {
  color: #eb0099;
}

.social-media-links .fa-linkedin:hover {
  color: #10c0f5;
}

.social-media-links .fa-youtube:hover {
  color: #e90017;
}

.home-banner {
  background-image: url("../assets/images/home-banner.webp");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 89vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-banner {
  background-image: url("../assets/images/aboutbackground.webp");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner-heading {
  color: #fff;
  font-size: 58px;
  font-family: "OpenSans-ExtraBold";
  text-align: center;
}

.banner-sub-heading {
  color: #fff;
  font-size: 20px;
  font-family: "OpenSans-Regular";
  text-align: center;
  line-height: 28px;
}

.marquee-container {
  height: 65px;
  overflow: hidden;
  line-height: 30px;
  background-color: #000;
}

.marquee-container .marquee {
  top: 0;
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

.marquee-container .marquee2 {
  animation-delay: 15s;
}

.marquee span {
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-right: 1px solid #828080;
  padding: 0px 35px;
}

@keyframes marquee {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

#wistia_chrome_22 {
  border-radius: 12px !important;
}

.play-icon-video {
  width: 70px;
  height: auto;
  border-radius: 50%;
  animation: borderPulseVideo 1000ms infinite ease-out;
}

@keyframes borderPulseVideo {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(247, 68, 68, 0.4),
      0px 0px 0px 0px rgb(219 1 1);
  }

  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(41, 39, 39, 0.2),
      0px 0px 0px 10px rgba(255, 255, 255, 0);
  }
}

.home-video-section {
  background: linear-gradient(114.26deg, #000000 73.2%, #d32b3a 157.6%);
}

.home-video-para {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
}

.span-red-content {
  color: #d32b3a;
  font-family: "OpenSans-SemiBold";
}

.circle-center {
  height: 74px;
  width: 74px;
  border-radius: 50%;
  text-align: center;
  padding: 22px 17px;
  position: absolute;
  background: #940404d1;
  animation: borderPulseVideo 1000ms infinite ease-out;
}

.circle-center-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#circle svg {
  width: 250px;
  height: 240px;
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 14s;
  -moz-animation-duration: 14s;
  -ms-animation-duration: 14s;
  -o-animation-duration: 14s;
  animation-duration: 14s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#circle .text-rotatet {
  font-size: 18px;
  color: #fff;
  white-space: nowrap !important;
}

.down-arrow-rotate {
  width: 35px;
  height: auto;
}

.section-heading {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  font-family: "OpenSans-ExtraBold";
  margin-bottom: 10px;
}

.section-sub-heading {
  font-size: 25px;
  font-family: "OpenSans-SemiBold";
  color: #000;
}

.hands-on-left-text {
  color: #000;
  padding: 12px 20px;
  font-family: "OpenSans-SemiBold";
  width: 100%;
  display: block;
  cursor: pointer;
  margin-bottom: 10px;
}

.hands-on-left-text-disabled {
  color: #3f3e3e;
  padding: 12px 20px;
  font-family: "OpenSans-SemiBold";
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

.hands-on-left-text:hover,
.hands-on-left-text.active {
  color: #ffffff;
  padding: 12px 20px;
  background-color: #d32b3a;
  border-radius: 8px;
  font-family: "OpenSans-SemiBold";
}

.hands-on-section {
  background-color: #fbf9eb;
}

.hands-on-right-video {
  margin-bottom: 25px;
}

.hands-on-left-content {
  position: sticky;
  top: 100px;
  z-index: 1000;
  height: auto;
  background-color: #fbf9eb;
}

.right-side-video-content-para {
  color: #000;
  font-family: "OpenSans-SemiBold";
  margin-top: 10px;
}

.home-award-img {
  width: 100%;
  height: auto;
}

.aboutsection {
  margin-top: 90px;
}

.aboutheading1 {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  font-family: "OpenSans-ExtraBold";
  margin-bottom: 40px;
  padding-bottom: 5px;
}

/* .abtpara1 {
  margin-top: 45px;
} */

.aboutpara1 {
  text-align: justify;
  color: #1e1e1e;
}

.aboutpara {
  text-wrap: nowrap;
}

/* .aboutleft2 {
  margin-top: 60px;
} */

.tickicons {
  background-color: #d32b3a;
  color: white;
  padding: 6px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  margin-top: 2px;
}

.abtpoints {
  font-family: "OpenSans-Bold";
  color: #1e1e1e;
  font-size: 18px;
  padding-left: 7px;
}

.pointsflex {
  display: flex;
  padding-left: 5px;
  width: 40%;
}

.aboutspcbtwn {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  margin-bottom: 15px;
}

.abteagle img {
  width: 100%;
  /* height: 50%; */
}

.dharaniname {
  background: linear-gradient(96.11deg, #121112 -20.8%, #d32b3a 59.06%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "OpenSans-ExtraBold";
}

.abtcfounderpara {
  color: #1e1e1e;
  font-size: 23px;
  line-height: 30px;
  font-family: "OpenSans-ExtraBold";
}

.secmargintop {
  margin-top: 80px;
}

.secmarginbottom {
  margin-bottom: 40px;
}

.foundflex {
  display: flex;
  /* flex-direction: row; */
}

.foundflex p {
  font-size: 17px;
  margin-left: 5px;
  margin-bottom: 20px;
}

.foundbtopara {
  margin-top: 50px;
}

.aboutheading2 {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  font-family: "OpenSans-ExtraBold";
  /* margin-top: 50px; */
  padding-left: 42px;
  margin-bottom: 40px;
}

.pedolgyhead {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-family: "OpenSans-ExtraBold";
}

.pedolgyhead2 {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 15px;
  font-family: "OpenSans-ExtraBold";
}

.pedolgypara {
  color: #1e1e1e;
  font-size: 16px;
  margin: 20px 0px 20px;
  margin-bottom: 0px;
  /* font-family: "OpenSans-ExtraBold"; */
}

.pedolgypara3 {
  margin-top: 20px;
  margin-bottom: 0px;
}

.ubuntusec {
  background-color: #f8f7f8;
}

.aboutheading3 {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  font-family: "OpenSans-ExtraBold";
  line-height: 55px;
  margin-bottom: 40px;
}

.ubuntudiv {
  margin: 50px 0px 40px;
}

.abtubuntupara {
  font-weight: 600;
  font-size: 22px;
  margin-top: 25px;
}

.pwrcommunity {
  background: linear-gradient(88.43deg, #121112 0.23%, #d32b3a 44.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-family: "OpenSans-ExtraBold";
  margin-top: 30px;
}

.pwrcommpara {
  color: #1e1e1e;
  font-size: 16px;
}

.pwrcommunity2 {
  background: linear-gradient(88.43deg, #121112 0.23%, #d32b3a 44.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  font-family: "OpenSans-ExtraBold";
  /* margin-top: 20px; */
}

.howteach {
  font-size: 28px;
  /* font-weight: 800; */
  color: #000000;
  font-family: "OpenSans-ExtraBold";
}

.eagle-award-home-left-content {
  font-size: 58px;
  font-family: "OpenSans-SemiBold";
  color: #1e1e1e;
  line-height: 74px;
}

.eagle-award-gradient {
  background: linear-gradient(90.5deg, #121112 0.43%, #d32b3a 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 58px;
  font-family: "OpenSans-ExtraBold";
}

.left-para-subContent {
  color: #1e1e1e;
  font-family: "OpenSans-SemiBold";
  font-size: 22px;
  line-height: 36px;
}

.home-end-to-end-team-sections {
  background: linear-gradient(53.22deg, #d32b3a -92.39%, #ffffff 25.23%);
}

.image-sections {
  width: 100%;
  height: auto;
}

.width-left-circle-content {
  width: 50%;
}

.circle-center-left {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  text-align: center;
  padding: 22px 17px;
  position: absolute;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-center-rotate-img {
  width: 80px;
  height: auto;
}

#home-below-section {
  scroll-margin-top: 120px;
}

.choose-us-sub-heading {
  color: #1e1e1e;
  font-family: "OpenSans-Bold";
  font-size: 20px;
  line-height: 30px;
}

.choose-us-para-text {
  color: #1e1e1e;
  font-size: 16px;
}

.home-student-says-section {
  background-color: #f8f7f8;
}

.students-testi-heading {
  color: #1e1e1e;
  font-family: "OpenSans-Bold";
  font-size: 16px;
  height: 75px;
}

.students-testi-card-content {
  padding: 20px;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 25px;
}

.students-testi-para {
  color: #1e1e1e;
  font-size: 12px;
  height: 210px;
}

.play-icon-video-testi {
  width: 40px !important;
  height: auto;
  border-radius: 50%;
}

.home-student-says-section .owl-carousel .owl-nav.disabled {
  display: block !important;
}

.home-student-says-section .owl-carousel .owl-nav button.owl-prev,
.home-student-says-section .owl-carousel .owl-nav button.owl-next {
  background: none;
  color: #a01d26;
  border: none;
  padding: 0 !important;
  font: message-box;
  font-size: 30px !important;
  position: absolute;
  top: -90px;
  right: 50px;
  width: 40px;
  height: 40px;
  border: 1px solid #a01d26 !important;
  border-radius: 50px !important;
}

.home-student-says-section .owl-carousel .owl-nav button.owl-prev {
  right: 100px;
}

.owl-carousel .owl-nav button.owl-prev span,
.owl-carousel .owl-nav button.owl-next span {
  position: absolute !important;
  top: -3px !important;
  left: 15px !important;
  color: #a01d26;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #a01d26 !important;
  color: #fff !important;
  text-decoration: none;
}

.owl-theme .owl-nav [class*="owl-"]:hover > span {
  color: #fff !important;
}

.owl-carousel .owl-nav button.owl-prev span:hover,
.owl-carousel .owl-nav button.owl-next span:hover {
  color: #ffffff;
}

.result-tab {
  color: #8a8a8a;
  font-family: "OpenSans-Bold";
  text-decoration: none;
  font-size: 28px;
}

.result-tab.active,
.result-tab:hover {
  color: #000000;
  font-family: "OpenSans-Bold";
  cursor: pointer;
}

.results-content {
  background-color: #f8f7f8;
  position: relative;
}

.result-banner {
  background-image: url("../assets/images/results-banner.webp");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding: 20px;
  overflow: hidden;
}

.result-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.result-heading {
  position: relative;
  z-index: 2;
}

.result-bg-images {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.result-image {
  position: absolute;
  width: 100px;
  /* Adjust the size as needed */
  height: auto;
  opacity: 0;
  animation: animateImages 5s ease-out forwards;
}

@keyframes animateImages {
  0% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.road-map-img {
  width: 100%;
  height: auto;
}

.course-road-map {
  background-image: url("../assets/images/road-map-bg.webp");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.road-map-text {
  background: linear-gradient(94.76deg, #121112 -47.33%, #d32b3a 102.89%);
  color: #fff;
  font-family: "OpenSans-SemiBold";
  padding: 15px 25px;
  border-radius: 8px;
  font-size: 14px;
  width: fit-content;
}

.road-map-content {
  position: absolute;
  pointer-events: none;
  z-index: 10000;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.road-map-text1 {
  position: absolute;
  right: 13%;
  top: 6%;
}

.road-map-text2 {
  position: absolute;
  top: 14.5%;
  right: 4%;
}

.road-map-text3 {
  position: absolute;
  top: 23%;
  right: 28%;
}

.road-map-text4 {
  position: absolute;
  top: 27.5%;
  left: 22%;
}

.road-map-text5 {
  position: absolute;
  top: 32.2%;
  left: 3%;
}

.road-map-text6 {
  position: absolute;
  top: 39%;
  right: 39%;
}

.road-map-text7 {
  position: absolute;
  top: 51%;
  right: 42%;
}

.road-map-text8 {
  position: absolute;
  bottom: 28.5%;
  right: 20%;
}

.road-map-text9 {
  position: absolute;
  bottom: 17%;
  right: 7%;
}

/* @keyframes shake {
  0%, 100% { transform: translateY(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateY(-5px); }
  20%, 40%, 60%, 80% { transform: translateY(5px); }
}

.shake-animation {
  animation: shake 1.5s infinite ease-in-out;
} */

/* @keyframes zoomOut {

  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
} */
/* 
.shake-animation {
  animation: zoomOut 4s infinite ease-in-out;
}
*/
.road-map-end-img {
  width: 400px;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.course-side-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.course-sub-heading {
  font-size: 22px;
  font-family: "OpenSans-Bold";
  color: #1e1e1e;
}

.course-card-para {
  font-size: 14px;
  color: #1e1e1e;
}

.row-margin-bottom {
  margin-bottom: 50px;
}

.apply-course-card {
  background: linear-gradient(95.26deg, #121112 68.66%, #d32b3a 132.28%);
  padding: 5%;
  border-radius: 16px;
}

.apply-course-text {
  color: #fff;
  font-size: 13px;
}

.apply-course-heading {
  color: #fff;
  font-size: 18px;
  font-family: "OpenSans-Bold";
}

.upcoming-batch {
  background-color: #d32b3a;
  padding: 8px 20px;
  color: #fff;
  font-size: 13px;
  border-radius: 6px;
  position: absolute;
  margin-bottom: 0px;
  top: -15px;
  left: 7%;
}

.skool-imgs {
  width: 100%;
  height: auto;
}

.students-testi-card {
  margin-bottom: 20px;
}

.stu-testi-course {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  height: auto;
}

.outer-pages .navbar {
  background-color: #000 !important;
}

.scroll {
  --t: 40s;
  /* Default value for animation duration */
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(114.26deg, #000000 73.2%, #d32b3a 157.6%);
}

.scroll div {
  white-space: nowrap;
  animation: animate var(--t) linear infinite;
  animation-delay: calc(var(--t) / -1);
}

.scroll div:nth-child(2) {
  animation: animate2 var(--t) linear infinite;
  animation-delay: calc(var(--t) / -2);
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-200%);
  }
}

.scroll div span {
  display: inline-flex;
  margin: 10px;
  padding: 5px 30px;
  color: #ffffff;
  transition: 0.5s;
  text-align: center;
  font-family: "OpenSans-Bold";
  font-size: 24px;
  border-right: 1px solid #b6b6b6;
}

/* .scroll div span:hover {
  background: #d32b3a;
  cursor: pointer;
} */

.pulse:hover,
.pulse:focus {
  animation: pulse 1s infinite;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse {
  0%,
  100% {
    box-shadow: 0 0 0 0 var(--hover);
  }

  50% {
    box-shadow: 0 0 0 2em var(--hover);
  }
}

.zoom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* .play-icon-video-testi {
  border-radius: 50%;
  animation: zoom 5s infinite;
} */
/* 
@keyframes zoom {

  0%,
  100% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1.3);
  }
} */

.results-content .Modal-View {
  width: 58% !important;
}

.results-content .modal-content {
  background-color: #fff0;
}

.results-content .close-btn {
  position: absolute;
  right: -5px;
  left: unset;
  top: -2px;
  z-index: 250000;
}

.pulse-button {
  animation: borderPulse 1000ms infinite ease-out;
}

/* Declare border pulse animation */
@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255, 0.4),
      0px 0px 0px 0px rgba(255, 255, 255, 1);
  }

  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255, 0.2),
      0px 0px 0px 10px rgba(255, 255, 255, 0);
  }
}

.footerRowContent {
  display: flex;
  justify-content: space-between;
}

.order-list-course {
  list-style: auto;
  padding-left: 15px;
}

.font-bold {
  font-family: "OpenSans-Bold";
}

.unorder-list-course {
  list-style: disc;
  padding-left: 15px;
}

.expertbanner-content {
  margin-bottom: 15%;
}

.results-header {
  position: sticky;
  top: 85px;
  z-index: 1000;
  width: 100%;
  background-color: #f8f7f8;
}

.exitIntentPopup .Modal-View {
  width: 40%;
}

.arial-regular {
  font-family: "OpenSans-Regular";
}

.arial-Bold {
  font-family: "OpenSans-Bold";
}

.result-tab.active,
.result-tab:hover {
  position: relative;
  display: inline-block;
}

.result-tab.active::after,
.result-tab:hover::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #af0404;
  animation: expand 3s infinite;
}

@keyframes expand {
  0% {
    width: 0;
  }

  50% {
    width: 70%;
  }

  100% {
    width: 0;
  }
}

.stud-testi-card-img-home {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: auto;
}

.events-tab {
  /* background: linear-gradient(90.5deg, #121112 0.43%, #741E26 52.47%, #D32B3A 103.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-family: "OpenSans-Bold";
  text-decoration: none;
  font-size: 28px;
  padding: 5px 0px;
  margin: auto 20px;
  color: #fff;
}

.events-tab.active,
.events-tab:hover {
  color: #a01d26;
  /* -webkit-background-clip: unset;
  -webkit-text-fill-color: #ffff; */
  font-family: "OpenSans-Bold";
  cursor: pointer;
  padding: 5px 20px;
  margin: auto 0px;
  background-color: #fbf9eb;
  border-radius: 6px;
  display: block;
  width: 100%;
}

/* .events-tab,
.events-tab{
  position: relative;
  display: inline-block;
}

.events-tab::after,
.events-tab::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #af0404;
  animation: expand-events 3s infinite;
}


@keyframes expand-events {
  0% {
    width: 0;
  }

  50% {
    width: 70%;
  }

  100% {
    width: 0;
  }
} */

.text-justify {
  text-align: justify;
}

.bg-scope-tnds-sections {
  background-color: #fbf9eb;
}

.scope-white-heading {
  font-size: 35px;
  font-family: "OpenSans-ExtraBold";
  background: linear-gradient(88.43deg, #121112 0.23%, #d32b3a 44.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 2px;
}

.bg-scope-inner-card {
  background: linear-gradient(107.71deg, #121112 61.3%, #d32b3a 125.34%);
  padding: 0px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.scope-heading {
  font-family: "OpenSans-Bold";
  color: #000;
  font-size: 24px;
  height: auto;
}

.blogs-content .blogs-heading {
  font-family: "OpenSans-Bold";
  color: #000;
  font-size: 24px;
  height: 85px;
}

.scope-para {
  color: #000000;
  font-size: 16px;
  /* text-align: justify; */
}

.scope-img {
  width: 60%;
  height: auto;
}

.pad-scope {
  padding: 20px;
}

.width-75 {
  width: 60%;
}

.testi-events {
  border-radius: 6px;
  width: 100%;
  height: auto;
}

.eagle-awards-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 6px;
}

.tnds-2025 {
  background: linear-gradient(107.71deg, #121112 61.3%, #d32b3a 125.34%);
}

.blog-tab-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  position: sticky;
  top: 85px;
  width: 100%;
  z-index: 100;
  background-color: #fbf9eb;
}

.blogs-tab-content {
  color: #838383;
  font-family: "OpenSans-SemiBold";
  text-decoration: none;
  font-size: 22px;
  cursor: pointer;
  padding: 12px 0px;
  margin-top: 10px;
  margin: auto 5px;
}

.blogs-tab-content.active,
.blogs-tab-content:hover {
  color: #000000;
  display: block;
  position: relative;
}

.blogs-tab-content.active::after,
.blogs-tab-content:hover::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #af0404;
  animation: expand 3s infinite;
}

.blogs-img-top {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.blogs-img {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.bg-blog {
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}

.date-blog {
  font-size: 12px;
  color: #353434;
  font-family: "OpenSans-Bold";
}

.read-more-text {
  margin-bottom: 0px;
  color: #d32b3a;
  font-family: "OpenSans-Bold";
  font-size: 15px;
  text-decoration: none;
}

.read-more-text:hover {
  text-decoration: underline;
  color: #000;
}

.padding-blogs {
  padding: 20px;
}

.border-end-blogs {
  width: 2px;
  height: 25px;
  background-color: #797878;
  transform: rotate(14deg);
  margin-left: 15px;
  display: inline-block;
}

.carousel-banner-expert {
  width: 60%;
  height: auto;
  position: absolute;
  right: 30%;
  bottom: -7%;
  transform: translate(16%, 0%);
}

.events-heading {
  background: linear-gradient(
    90.5deg,
    #121112 0.43%,
    #741e26 52.47%,
    #d32b3a 103.11%
  );
  border-radius: 8px;
}

.course-videos {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
